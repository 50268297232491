<template>
  <div class="fare">
    <v-progress-linear
    indeterminate
    color="secondary"
    height="10"
    top
    :active="loaded"
    ></v-progress-linear>
    <v-container fluid>
      <overlay v-if="$store.state.hotelTimeOut && currentPage !== 3" />
      <div>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
          {{ text }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="snackbar = false">mdi-close</v-icon>
          </v-row>
        </v-snackbar>
        <div v-if="!loaded && !dataReady">
          <v-alert
          prominent
          icon="mdi-alert"
          text
          type="warning"
          class="mx-auto my-5"
          >
            <v-row align="center">
              <v-col class="grow">
                <span v-if="soldOut">This room has been sold out please search for another room !</span>
                <span v-else>Sorry, The room or rooms you want aren't available any more !</span>
              </v-col>
              <v-col class="shrink">
                <v-btn color="blueDark" @click="$router.go(-1)"> Go Back to rooms</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>
        <div v-if="!loaded && dataReady">
          <v-container fluid v-if="availability.AvailableForBook || availability.AvailableForConfirmBook">
             <v-stepper class="elevation-0" v-model="el">
              <v-stepper-header class="elevation-0">
                <v-stepper-step
                :complete="el > 1"
                :editable="el === 2"
                :step="1"
                color="blueDark"
                >
                  Review
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el > 2"
                :step="2"
                color="blueDark"
                :editable="el === 3"
                >
                <!-- -->
                  Guests
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el > 3"
                :step="3"
                color="blueDark"
                >
                  Payment
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el === 4"
                :step="4"
                color="blueDark"
                >
                  Confirmation
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                  <v-stepper-content :step="1">
                    <v-row>
                      <v-col cols="12" md="8" lg="9">
                        <v-alert
                        border="left"
                        color="warning"
                        dark
                        v-model="alert"
                        dismissible
                        >
                          {{alertText}}
                        </v-alert>
                        <v-card class="pa-5" outlined>
                          <v-row no-gutters>
                            <h3 class="font-weight-bold">{{hotel.HotelName}}</h3>
                            <v-rating :length="hotel.HotelStars" color="warning" readonly small :value="Number(hotel.HotelStars)" full-icon="mdi-star"></v-rating>
                          </v-row>
                          <v-row justify="space-between" no-gutters>
                            <p><v-icon small class="mr-1" color="secondary">mdi-phone</v-icon>{{hotel.HotelPhone}}</p>
                            <p><v-icon small class="mr-1 ml-1" color="secondary">mdi-fax</v-icon>{{hotel.HotelFax}}</p>
                          </v-row>
                          <p class="body-2 mb-0 mt-1">
                            <v-icon small class="mt-0" color="secondary">mdi-map-marker</v-icon>
                            {{hotel.HotelAddress}},{{hotel.HotelCity}},{{hotel.HotelCountry}}
                          </p>
                          <v-btn text color="blue" small @click="openMap(hotel.map)">
                            Show on map
                          </v-btn>
                          <br>
                        </v-card>
                        <v-card outlined class="pa-5 my-5">
                          <p class="body-1 font-weight-bold mt-5">Hotel policy</p>
                          <div v-html="availability.HotelPolicies"></div>
                        </v-card>

                        <v-card outlined class="pa-5" v-if="availability.CancellationPoliciesAvailable">
                          <p class="body-1 font-weight-bold mt-5">Cancelation</p>

                          <div v-if="availability.cancellationPolicies.firstCancellationDeadline">- Free cancellation until {{new Date(availability.cancellationPolicies.firstCancellationDeadline).toGMTString()}}.</div>
                          <div v-if="availability.cancellationPolicies.lastCancellationDeadLine">- The end time for the cancellation window is {{new Date(availability.cancellationPolicies.lastCancellationDeadLine).toGMTString()}} at which time the booking will become fully non-refundable.</div>
                          <div v-if="availability.cancellationPolicies.nonRefundableEndDate && availability.cancellationPolicies.nonRefundableStartDate">- Nights booked that fall within the exception window
                            {{new Date(availability.cancellationPolicies.nonRefundableStartDate).toGMTString()}} to {{new Date(availability.cancellationPolicies.nonRefundableEndDate).toGMTString()}}
                            provide no refund on cancellation.</div>
                          <div v-for="(policy, i) in availability.cancellationPolicies.policies" :key="'policy-'+i">
                            <div class="body-2" v-if="policy.chargeType === 'Nights' && policy.cancellationCharge > 0">- Cancellations made after {{new Date(policy.fromDate).toGMTString()}} are subject to a {{policy.cancellationCharge}} night(s) penalty plus taxes and fees.</div>
                            <div class="body-2" v-if="policy.chargeType === 'Fixed' && policy.cancellationCharge > 0">- Cancellations made after {{new Date(policy.fromDate).toGMTString()}} will result in an {{policy.currency + ' ' + policy.cancellationCharge}} fee.</div>
                            <div class="body-2" v-if="policy.chargeType === 'Percentage' && policy.cancellationCharge > 0">- Cancellations made after {{new Date(policy.fromDate).toGMTString()}} re subject to a {{policy.cancellationCharge}}% of total price penalty.</div>
                          </div>
                          <div>- If you fail to check-in for this reservation, or if you cancel or change this reservation after check-in, you may incur penalty charges at the discretion of the property of up to 100% of the booking value.</div>

                          <p class="body-1 font-weight-bold mt-5">Instructions</p>
                          <p v-if="availability.instructions.checkInInstructions" v-html="availability.instructions.checkInInstructions" class="body-2"></p>
                          <p v-if="availability.instructions.specialCheckInInstructions" v-html="availability.instructions.specialCheckInInstructions" class="body-2"></p>
                        </v-card>

                        <v-checkbox color="blueDark" id="book-terms-checkbox" v-model="acceptCancellation" label="I Accept cancellation policies"></v-checkbox>
                        <div class="d-flex">
                          <v-checkbox color="blueDark" id="book-terms-checkbox" v-model="acceptTerms" label="I Accept terms & conditions"></v-checkbox>
                          <router-link to="/terms" target="_blank" class="blue--text mt-5 ml-2">See terms & conditions</router-link>
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" lg="3">
                        <v-card outlined class="mb-5">
                          <v-alert
                          v-if="availability.priceChanged"
                          dense
                          dismissible
                          icon="mdi-alert"
                          text
                          type="warning"
                          class="ma-1"
                          >
                            Please note tht the price has been changed from the previous step!
                          </v-alert>
                          <v-card-text class="pa-5">
                            <h4 class="orange--text my-2">Days Rate</h4>
                            <v-expansion-panels class="elevation-1" flat>
                              <v-expansion-panel v-for="(rate, i) in priceDetails.daysRate" :key="'day-rate-' + i">
                                <v-expansion-panel-header>
                                  <div class="d-flex justify-space-between body-2">
                                    <div>{{new Date(rate.date).toDateString()}}</div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-if="rate.baseFare" class="d-flex justify-space-between body-2">
                                    <div>Base Fare</div>
                                    {{rate.baseFare | changeCurrency}}
                                  </div>
                                  <div v-if="rate.adjustment" class="d-flex justify-space-between body-2">
                                    <div>Adjustment</div>
                                    {{rate.adjustment | changeCurrency}}
                                  </div>
                                  <div v-if="rate.extra_person_fee" class="d-flex justify-space-between body-2">
                                    <div>Extra person fee</div>
                                    {{rate.extra_person_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.recovery_charges_and_fees" class="d-flex justify-space-between body-2">
                                    <div>Recovery charges and fees</div>
                                    {{rate.recovery_charges_and_fees | changeCurrency}}
                                  </div>
                                  <div v-if="rate.property_fee" class="d-flex justify-space-between body-2">
                                    <div>Property fee</div>
                                    {{rate.property_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.sales_tax" class="d-flex justify-space-between body-2">
                                    <div>Sales tax</div>
                                    {{rate.sales_tax | changeCurrency}}
                                  </div>
                                  <div v-if="rate.tax_and_service_fee" class="d-flex justify-space-between body-2">
                                    <div>Tax and service fee</div>
                                    {{rate.tax_and_service_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.traveler_service_fee" class="d-flex justify-space-between body-2">
                                    <div>Traveller service fee</div>
                                    {{rate.traveler_service_fee | changeCurrency}}
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>

                            <h4 class="orange--text my-3">Rate Summary</h4>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>Base Fare</div>
                              {{priceDetails.rateSummary.baseFare | changeCurrency}}
                            </div>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>Affiliate booking charge</div>
                              {{priceDetails.rateSummary.serviceFeesTotal | changeCurrency}}
                            </div>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>
                                <v-tooltip bottom color="blue darken-4">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                    v-bind="attrs"
                                    v-on="on"
                                    >Total taxes and service fees <v-icon color="blue darken-4" right small>mdi-information</v-icon></span>
                                  </template>
                                  <span class="caption">This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, <br> and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). <br /> This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, <br> the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, <br> which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</span>
                                </v-tooltip>
                              </div>
                              {{priceDetails.rateSummary.tax | changeCurrency}}
                            </div>
                            <v-card elevation="0" color="grey lighten-3" class="pa-1 my-1">
                              <v-card-title class="px-0 body-2 font-weight-bold">Fees paid at property</v-card-title>
                              <div v-for="(fee, n) in priceDetails.supplements" :key="n" class="d-flex justify-space-between">
                                <p class="mb-1  body-2 text-capitalize">{{fee.name.replaceAll('_', ' ')}}</p>
                                <p class="mb-1  body-2 text-capitalize">{{fee.currencyCode.replaceAll('_', ' ')}} {{fee.price}}</p>
                              </div>
                              <span class="caption">*This price includes taxes and stay taxes.</span>
                              <span class="caption">*This conversion is an approximate price based on the current exchange rate and may change before you stay.</span>
                            </v-card>
                            <!-- <div class="d-flex justify-space-between body-1 black--text">
                              <div>Total Price</div>
                              {{priceDetails.summary.totalFare | changeCurrency}}
                            </div> -->
                          </v-card-text>
                          <v-card-actions class="blueDark white--text pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="text-h5 font-weight-bold">Grand Total</p>
                              <p class="text-h5 font-weight-bold">{{priceDetails.rateSummary.totalFare | changeCurrency}}</p>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn
                    color="blueDark white--text"
                    @click="nextClicked(1)"
                    class="py-8"
                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                    >
                      Continue to Guests
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content :step="2">
                    <v-row>
                      <v-col cols="12" md="8" lg="9">
                        <v-card outlined class="pa-5">
                          <v-form v-model="valid" ref="form">
                            <p v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'" class="body-1 font-weight-bold secondary--text">Contact person details</p>
                            <v-row v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'">
                              <v-col cols="12" md="4" class="py-0">
                                <phoneInput :phone="phone.nationalNumber" @update="assignPhone" />
                              </v-col>
                              <v-col cols="12" md="4" class="py-0">
                                <v-text-field
                                outlined
                                v-model="contact_name_person"
                                :rules="fullNameRule"
                                label="Name"
                                required
                                color="blue"
                                autocomplete="off"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4" class="py-0">
                                <v-text-field
                                outlined
                                v-model="email"
                                :rules="[v => !!v || 'E-mail is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                                label="E-mail"
                                required
                                color="blue"
                                id="guest-email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-divider v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'"></v-divider>
                            <div class="my-3" v-for="(room, i) in rooms" :key="i">
                              <p class="body-1 font-weight-bold text-decoration-underline secondary--text">Room {{i + 1}}</p>
                              <v-row v-for="n in $store.state.hotelSearch.guestsInfo[i].adults" :key="(n * 100) + previousNumber('adult', i)">
                                <v-col cols="12">Adult {{n}}</v-col>
                                <v-col cols="12" sm="2">
                                    <v-select
                                    v-model="guestTitles[(n - 1) + (i) + (previousNumber('adult', i))]"
                                    :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                                    label="Title"
                                    outlined
                                    required
                                    :rules="[v => !!v || 'Item is required']"
                                    color="blue"
                                    :id="`room-${i}-adult-guest-${n}-title`"
                                    >
                                      <template v-slot:selection="{ item }">
                                        <span class="primary--text caption">{{item}}</span>
                                      </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                  outlined
                                  v-model="adultFirstNames[(n - 1) + (i) + (previousNumber('adult', i))]"
                                  :rules="nameRule"
                                  label="First name"
                                  required
                                  color="blue"
                                  autocomplete="off"
                                  :id="`room-${i}-adult-guest-${n}-fName`"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                  outlined
                                  v-model="adultLastNames[(n - 1) + (i) + (previousNumber('adult', i))]"
                                  :rules="nameRule"
                                  label="Last name"
                                  required
                                  color="blue"
                                  autocomplete="off"
                                  :id="`room-${i}-guest-adult-${n}-lName`"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <v-row justify="space-around">
                                  <p class="ma-0 mt-3">Leader</p>
                                  <input type="radio"
                                  style="width: 19px; height: 50px;"
                                  v-model="isLeader[i]"
                                  :value="(n - 1) + (i) + (previousNumber('adult', i))"
                                  >
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row v-for="n in $store.state.hotelSearch.guestsInfo[i].children" :key="(n) + previousNumber('child', i)">
                                  <v-col cols="12">Child {{n}}</v-col>
                                  <v-col cols="12" sm="4">
                                  <v-select
                                    v-model="childrenTitles[(n - 1) + (i) + (previousNumber('child', i))]"
                                    :items="['Mr', 'Miss']"
                                    label="Title"
                                    outlined
                                    required
                                    :rules="[v => !!v || 'Item is required']"
                                    color="blue"
                                    :id="`room-${i}-guest-child-${n}-title`"
                                    >
                                      <template v-slot:selection="{ item }">
                                        <span class="primary--text caption">{{item}}</span>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                    outlined
                                    v-model="childrenFirstNames[(n - 1) + (i) + (previousNumber('child', i))]"
                                    :rules="nameRule"
                                    label="First name"
                                    required
                                    color="blue"
                                    autocomplete="off"
                                    :id="`room-${i}-guest-child-${n}-fName`"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                    outlined
                                    v-model="childrenLastNames[(n - 1) + (i) + (previousNumber('child', i))]"
                                    :rules="nameRule"
                                    label="Last name"
                                    required
                                    color="blue"
                                    autocomplete="off"
                                    :id="`room-${i}-guest-child-${n}-lName`"
                                    ></v-text-field>
                                  </v-col>
                              </v-row>
                            </div>
                          </v-form>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" lg="3">
                        <v-card outlined class="mb-5">
                          <v-card-text class="pa-5">
                            <h4 class="orange--text my-2">Days Rate</h4>
                            <v-expansion-panels class="elevation-1" flat>
                              <v-expansion-panel v-for="(rate, i) in priceDetails.daysRate" :key="'day-rate-' + i">
                                <v-expansion-panel-header>
                                  <div class="d-flex justify-space-between body-2">
                                    <div>{{new Date(rate.date).toDateString()}}</div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-if="rate.baseFare" class="d-flex justify-space-between body-2">
                                    <div>Base Fare</div>
                                    {{rate.baseFare | changeCurrency}}
                                  </div>
                                  <div v-if="rate.adjustment" class="d-flex justify-space-between body-2">
                                    <div>Adjustment</div>
                                    {{rate.adjustment | changeCurrency}}
                                  </div>
                                  <div v-if="rate.extra_person_fee" class="d-flex justify-space-between body-2">
                                    <div>Extra person fee</div>
                                    {{rate.extra_person_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.recovery_charges_and_fees" class="d-flex justify-space-between body-2">
                                    <div>Recovery charges and fees</div>
                                    {{rate.recovery_charges_and_fees | changeCurrency}}
                                  </div>
                                  <div v-if="rate.property_fee" class="d-flex justify-space-between body-2">
                                    <div>Property fee</div>
                                    {{rate.property_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.sales_tax" class="d-flex justify-space-between body-2">
                                    <div>Sales tax</div>
                                    {{rate.sales_tax | changeCurrency}}
                                  </div>
                                  <div v-if="rate.tax_and_service_fee" class="d-flex justify-space-between body-2">
                                    <div>Tax and service fee</div>
                                    {{rate.tax_and_service_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.traveler_service_fee" class="d-flex justify-space-between body-2">
                                    <div>Traveller service fee</div>
                                    {{rate.traveler_service_fee | changeCurrency}}
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>

                            <h4 class="orange--text my-3">Rate Summary</h4>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>Base Fare</div>
                              {{priceDetails.rateSummary.baseFare | changeCurrency}}
                            </div>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>Affiliate booking charge</div>
                              {{priceDetails.rateSummary.serviceFeesTotal | changeCurrency}}
                            </div>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>
                                <v-tooltip bottom color="blue darken-4">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                    v-bind="attrs"
                                    v-on="on"
                                    >Total taxes and service fees <v-icon color="blue darken-4" right small>mdi-information</v-icon></span>
                                  </template>
                                  <span class="caption">This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, <br> and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). <br /> This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, <br> the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, <br> which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</span>
                                </v-tooltip>
                              </div>
                              {{priceDetails.rateSummary.tax | changeCurrency}}
                            </div>
                            <v-card elevation="0" color="grey lighten-3" class="pa-1 my-1">
                              <v-card-title class="px-0 body-2 font-weight-bold">Fees paid at property</v-card-title>
                              <div v-for="(fee, n) in priceDetails.supplements" :key="n" class="d-flex justify-space-between">
                                <p class="mb-1  body-2 text-capitalize">{{fee.name.replaceAll('_', ' ')}}</p>
                                <p class="mb-1  body-2 text-capitalize">{{fee.currencyCode.replaceAll('_', ' ')}} {{fee.price}}</p>
                              </div>
                              <span class="caption">*This price includes taxes and stay taxes.</span>
                              <span class="caption">*This conversion is an approximate price based on the current exchange rate and may change before you stay.</span>
                            </v-card>
                            <!-- <div class="d-flex justify-space-between body-1 black--text">
                              <div>Total Price</div>
                              {{priceDetails.summary.totalFare | changeCurrency}}
                            </div> -->
                          </v-card-text>
                          <v-card-actions class="blueDark white--text pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="text-h5 font-weight-bold">Grand Total</p>
                              <p class="text-h5 font-weight-bold">{{priceDetails.rateSummary.totalFare | changeCurrency}}</p>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn
                    color="blueDark white--text"
                    @click="nextClicked(2)"
                    class="my-5 py-8"
                    :loading="holdBtnLoad"
                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                    >
                      Continue To Payment
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content :step="3">
                    <v-row>
                      <v-col cols="12" md="8" lg="9">
                        <bookinData type="hotels" :items="guestsData" />
                        <address-card v-if="paymentType === 'credit'" />
                        <v-card class="pa-5 my-5" outlined>
                          <v-card-title>Payment</v-card-title>
                          <v-form v-model="valid1" ref="form1">
                            <v-radio-group v-if="$store.state.user && $cookies.isKey('userToken')" row color="secondary" v-model="paymentType">
                              <v-radio id="payment-radio__credit" color="secondary" value="credit">
                                <template v-slot:label>
                                  <small class="secondary--text">Credit Card</small>
                                </template>
                              </v-radio>
                              <v-radio id="payment-radio__points" color="secondary" value="points" v-if="$store.state.user.role === 'customer'" :disabled="$store.state.user.points < $store.state.user.minPointsToRedeem || $store.state.user.pointsToAmount < Math.floor(priceDetails.rateSummary.totalFare)">
                                <template v-slot:label>
                                  <span class="secondary--text" v-if="$store.state.user.points < $store.state.user.minPointsToRedeem || $store.state.user.pointsToAmount < Math.floor(priceDetails.rateSummary.totalFare)">Your Points aren't enough to cover this booking fees ({{$store.state.user.points.toLocaleString('en')}}p)</span>
                                  <span v-else class="secondary--text">Your Points ({{$store.state.user.points.toLocaleString('en')}}p)</span>
                                </template>
                              </v-radio>
                            </v-radio-group>
                            <p  v-if="paymentType === 'points'" class="body-1">You will be charged {{(priceDetails.rateSummary.totalFare * 170).toFixed(2)}} points </p>
                            <credit v-if="paymentType === 'credit'" @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                          </v-form>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" lg="3">
                        <v-card outlined class="mb-5">
                          <v-card-text class="pa-5">
                            <h4 class="orange--text my-2">Days Rate</h4>
                            <v-expansion-panels class="elevation-1" flat>
                              <v-expansion-panel v-for="(rate, i) in priceDetails.daysRate" :key="'day-rate-' + i">
                                <v-expansion-panel-header>
                                  <div class="d-flex justify-space-between body-2">
                                    <div>{{new Date(rate.date).toDateString()}}</div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-if="rate.baseFare" class="d-flex justify-space-between body-2">
                                    <div>Base Fare</div>
                                    {{rate.baseFare | changeCurrency}}
                                  </div>
                                  <div v-if="rate.adjustment" class="d-flex justify-space-between body-2">
                                    <div>Adjustment</div>
                                    {{rate.adjustment | changeCurrency}}
                                  </div>
                                  <div v-if="rate.extra_person_fee" class="d-flex justify-space-between body-2">
                                    <div>Extra person fee</div>
                                    {{rate.extra_person_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.recovery_charges_and_fees" class="d-flex justify-space-between body-2">
                                    <div>Recovery charges and fees</div>
                                    {{rate.recovery_charges_and_fees | changeCurrency}}
                                  </div>
                                  <div v-if="rate.property_fee" class="d-flex justify-space-between body-2">
                                    <div>Property fee</div>
                                    {{rate.property_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.sales_tax" class="d-flex justify-space-between body-2">
                                    <div>Sales tax</div>
                                    {{rate.sales_tax | changeCurrency}}
                                  </div>
                                  <div v-if="rate.tax_and_service_fee" class="d-flex justify-space-between body-2">
                                    <div>Tax and service fee</div>
                                    {{rate.tax_and_service_fee | changeCurrency}}
                                  </div>
                                  <div v-if="rate.traveler_service_fee" class="d-flex justify-space-between body-2">
                                    <div>Traveller service fee</div>
                                    {{rate.traveler_service_fee | changeCurrency}}
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>

                            <h4 class="orange--text my-3">Rate Summary</h4>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>Base Fare</div>
                              {{priceDetails.rateSummary.baseFare | changeCurrency}}
                            </div>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>Affiliate booking charge</div>
                              {{priceDetails.rateSummary.serviceFeesTotal | changeCurrency}}
                            </div>
                            <div class="d-flex justify-space-between body-2 black--text">
                              <div>
                                <v-tooltip bottom color="blue darken-4">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                    v-bind="attrs"
                                    v-on="on"
                                    >Total taxes and service fees <v-icon color="blue darken-4" right small>mdi-information</v-icon></span>
                                  </template>
                                  <span class="caption">This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, <br> and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). <br /> This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, <br> the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, <br> which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</span>
                                </v-tooltip>
                              </div>
                              {{priceDetails.rateSummary.tax | changeCurrency}}
                            </div>
                            <v-card elevation="0" color="grey lighten-3" class="pa-1 my-1">
                              <v-card-title class="px-0 body-2 font-weight-bold">Fees paid at property</v-card-title>
                              <div v-for="(fee, n) in priceDetails.supplements" :key="n" class="d-flex justify-space-between">
                                <p class="mb-1  body-2 text-capitalize">{{fee.name.replaceAll('_', ' ')}}</p>
                                <p class="mb-1  body-2 text-capitalize">{{fee.currencyCode.replaceAll('_', ' ')}} {{fee.price}}</p>
                              </div>
                              <span class="caption">*This price includes taxes and stay taxes.</span>
                              <span class="caption">*This conversion is an approximate price based on the current exchange rate and may change before you stay.</span>
                            </v-card>
                          </v-card-text>
                          <v-card-actions class="blueDark white--text pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="text-h5 font-weight-bold">Grand Total</p>
                              <p class="text-h5 font-weight-bold">{{priceDetails.rateSummary.totalFare | changeCurrency}}</p>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn
                    color="blueDark white--text"
                    @click="nextClicked(3)"
                    class="py-8"
                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                    >
                      Reserve
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content :step="4">
                    <v-container>
                      <h3 class="display-1 font-weight-regular my-5 blueDark--text text-center">Thank you for booking with us</h3>
                      <h3 class="display-1 font-weight-thin primary--text text-center">Your rooms were booked successfully, we will send you an email with more details.</h3>
                      <h3 class="body-1 font-weight-bold primary--text mt-5 text-center">Your booking number is {{bookingNo}}</h3>
                    </v-container>
                    <v-card outlined class="mb-5 mx-auto" max-width="500">
                      <v-card-text class="pa-5">
                        <h4 class="orange--text my-2">Days Rate</h4>
                        <v-expansion-panels class="elevation-1" flat>
                          <v-expansion-panel v-for="(rate, i) in priceDetails.daysRate" :key="'day-rate-' + i">
                            <v-expansion-panel-header>
                              <div class="d-flex justify-space-between body-2">
                                <div>{{new Date(rate.date).toDateString()}}</div>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div v-if="rate.baseFare" class="d-flex justify-space-between body-2">
                                <div>Base Fare</div>
                                {{rate.baseFare | changeCurrency}}
                              </div>
                              <div v-if="rate.adjustment" class="d-flex justify-space-between body-2">
                                <div>Adjustment</div>
                                {{rate.adjustment | changeCurrency}}
                              </div>
                              <div v-if="rate.extra_person_fee" class="d-flex justify-space-between body-2">
                                <div>Extra person fee</div>
                                {{rate.extra_person_fee | changeCurrency}}
                              </div>
                              <div v-if="rate.recovery_charges_and_fees" class="d-flex justify-space-between body-2">
                                <div>Recovery charges and fees</div>
                                {{rate.recovery_charges_and_fees | changeCurrency}}
                              </div>
                              <div v-if="rate.property_fee" class="d-flex justify-space-between body-2">
                                <div>Property fee</div>
                                {{rate.property_fee | changeCurrency}}
                              </div>
                              <div v-if="rate.sales_tax" class="d-flex justify-space-between body-2">
                                <div>Sales tax</div>
                                {{rate.sales_tax | changeCurrency}}
                              </div>
                              <div v-if="rate.tax_and_service_fee" class="d-flex justify-space-between body-2">
                                <div>Tax and service fee</div>
                                {{rate.tax_and_service_fee | changeCurrency}}
                              </div>
                              <div v-if="rate.traveler_service_fee" class="d-flex justify-space-between body-2">
                                <div>Traveller service fee</div>
                                {{rate.traveler_service_fee | changeCurrency}}
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <h4 class="orange--text my-3">Rate Summary</h4>
                        <div class="d-flex justify-space-between body-2 black--text">
                          <div>Base Fare</div>
                          {{priceDetails.rateSummary.baseFare | changeCurrency}}
                        </div>
                        <div class="d-flex justify-space-between body-2 black--text">
                          <div>Affiliate booking charge</div>
                          {{priceDetails.rateSummary.serviceFeesTotal | changeCurrency}}
                        </div>
                        <div class="d-flex justify-space-between body-2 black--text">
                          <div>
                            <v-tooltip bottom color="blue darken-4">
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >Total taxes and service fees <v-icon color="blue darken-4" right small>mdi-information</v-icon></span>
                              </template>
                              <span class="caption">This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, <br> and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). <br /> This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, <br> the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, <br> which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</span>
                            </v-tooltip>
                          </div>
                          {{priceDetails.rateSummary.tax | changeCurrency}}
                        </div>
                        <v-card elevation="0" color="grey lighten-3" class="pa-1 my-1">
                          <v-card-title class="px-0 body-2 font-weight-bold">Fees paid at property</v-card-title>
                          <div v-for="(fee, n) in priceDetails.supplements" :key="n" class="d-flex justify-space-between">
                            <p class="mb-1  body-2 text-capitalize">{{fee.name.replaceAll('_', ' ')}}</p>
                            <p class="mb-1  body-2 text-capitalize">{{fee.currencyCode.replaceAll('_', ' ')}} {{fee.price}}</p>
                          </div>
                          <span class="caption">*This price includes taxes and stay taxes.</span>
                          <span class="caption">*This conversion is an approximate price based on the current exchange rate and may change before you stay.</span>
                        </v-card>
                      </v-card-text>
                      <v-card-actions class="blueDark white--text pa-5">
                        <v-row no-gutters justify="space-between">
                          <p class="text-h5 font-weight-bold">Grand Total</p>
                          <p class="text-h5 font-weight-bold">{{priceDetails.rateSummary.totalFare | changeCurrency}}</p>
                        </v-row>
                      </v-card-actions>
                    </v-card>

                    <v-btn
                    color="blueDark white--text"
                    @click="closeTab"
                    class="py-8 d-block mx-auto px-15"
                    >
                      Done
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
          </v-container>
          <div v-else>
            <p class="font-weight-bold body-1 text-center">The room or rooms you want aren't available any more <small class="body-1 blue--text" id="returnBtn" @click="$router.go(-1)">Return</small></p>
          </div>
        </div>
      </div>
      <v-dialog
      v-model="dialog"
      max-width="80%"
      >
          <gmap-map
          :center="center"
          :zoom="16"
          style="width:100%;  height: 500px;"
          zoomControl
          class="mt-1"
          >
              <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center=m.position"
              :clickable="true"
              :draggable="true"
              ></gmap-marker>
          </gmap-map>
      </v-dialog>
      <v-dialog
      v-model="priceChangeDialog"
      max-width="500"
      persistent
      >
        <v-card>
          <v-card-title
            class="headline secondary"
            primary-title
          >
            Price Change
          </v-card-title>

          <v-card-text v-if="priceDetails.rateSummary">
            Please note that some rooms' prices have been changeda and the new price is <span class="body-1 primary--text font-weight-bold"> {{ priceDetails.rateSummary.totalFare }}</span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="priceChangeDialog = false; $router.push({ name: 'hotelsResults' })"
            >
              Back To Results
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="priceChangeDialog = false; showOverlay = true; sendPaymentDataToAnet();"
            >
              Book
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { bookHotel, holdRooms, hotelAvailability, headers, headersNoAuth } from '../links'
import overlay from '../components/overlay'
import bookinData from '../components/bookingDataTable'
import credit from '../components/credit'
import phoneInput from '../components/mobile.vue'
import addressCard from '../components/address info.vue'

export default {
  title: '-Hotel Book',
  components: {
    overlay,
    bookinData,
    credit,
    phoneInput,
    addressCard
  },
  data () {
    return {
      el: 1,
      showAlert: true,
      loaded: true,
      dataReady: false,
      dialog: false,
      alert: false,
      paymentType: 'credit',
      acceptCancellation: false,
      acceptTerms: false,
      alertText: '',
      currentPage: 0,
      searcTerms: {},
      hotel: {},
      rooms: [],
      zip: '',
      address: '',
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      nameRule: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      fullNameRule: [v => (!!v && v.length > 1) || 'Full name is required (First and last names)',
        v => /^[A-Za-z]([-']?[A-Za-z]+)*( [A-Za-z]([-']?[A-Za-z]+)*)+$/.test(v) || 'Name Must be letters only'
      ],
      nextLabel: 'Continue',
      valid: true,
      valid1: true,
      snackbar: false,
      text: '',
      color: '',
      showOverlay: false,
      isLeader: [],
      guestTitles: [],
      childrenTitles: [],
      adultFirstNames: [],
      adultLastNames: [],
      childrenFirstNames: [],
      childrenLastNames: [],
      email: '',
      contact_name_person: '',
      phone: '',
      cardNum: '',
      cardCode: '',
      creditType: '',
      cardHolder: '',
      cardExpireDate: '',
      markers: [],
      center: {
        lat: 0,
        lng: 0
      },
      availability: {},
      menu: false,
      supplements: [],
      bookingNo: '',
      guestsData: [],
      priceChangeDialog: false,
      newPriceBody: {},
      hotelsOverlay: false,
      authorizeNetToken: null,
      priceDetails: {},
      soldOut: false,
      holdBtnLoad: false,
      holdingRefernce: ''
    }
  },
  watch: {
    el (newVal) {
      if (newVal === 3) {
        document.getElementById('card-address').addEventListener('input', (e) => {
          this.$store.dispatch('setWrittenAddress', e.target.value)
        })
      }
    }
  },
  methods: {
    sendPaymentDataToAnet () {
      if (this.paymentType === 'credit') {
        const authData = {}
        authData.clientKey = process.env.NODE_ENV === 'development' ? '5RwWyDRc9cD6Cm5K3dsK33zWuWz6qRz6V2bC3nz4q3RyTfLVWt5A4XS9RcK2w8AY' : '5VvrYqDNaPh2Tk8h3gXmCqpxT49hEZ8XFfR83M9w8J25K9EsPBEups2yr7NR6sM4'
        authData.apiLoginID = process.env.NODE_ENV === 'development' ? '4U9Ye5Hdq' : '5g2v4xS6Zr'
        const cardData = {}
        cardData.cardNumber = this.cardNum
        cardData.month = `${this.cardExpireDate.split('-')[1]}`
        cardData.year = `${this.cardExpireDate.split('-')[0]}`
        cardData.cardCode = this.cardCode
        cardData.cardHolder = this.cardHolder

        const secureData = {}
        secureData.authData = authData
        secureData.cardData = cardData

        window.Anet.dispatchData(secureData, (response) => {
          this.showOverlay = false
          if (response.messages.resultCode === 'Error') {
            for (let index = 0; index < response.messages.message.length; index++) {
              this.snackbar = true
              this.text = 'error :' + response.messages.message[index].code + ': ' + response.messages.message[index].text
              this.color = 'red'
            }
          } else {
            this.authorizeNetToken = response.opaqueData
            this.book()
          }
        })
      } else {
        this.book()
      }
    },
    setAddress (address, zip) {
      this.address = address
      this.zip = zip
    },
    assignPhone (phone) {
      this.phone = phone
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.creditType = 'VI'
          break
        case 'mastercard':
          this.creditType = 'CA'
          break
        case 'american-express':
          this.creditType = 'AX'
          break
        case 'discover':
          this.creditType = 'DS'
          break
        case 'diners-club':
          this.creditType = 'DN'
          break
        default:
          this.creditType = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cardCode = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = day ? `${day} ${months[month - 1]} ${year}` : `${months[month - 1]} ${year}`
      return newDate
    },
    openMap (position) {
      this.dialog = !this.dialog
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    changeRoomsPrice () {
      let price = 0
      let tax = 0
      let base = 0
      if (this.availability.priceChanged === 'true') {
        for (let i = 0; i < this.rooms.length; i++) {
          this.rooms[i].rateSummary = this.availability.rooms[i].rateSummary
        }
        let text = 'Please notice that the rooms prices has changed as follows'
        this.rooms.forEach(room => {
          price = price + room.rateSummary.totalFare
          tax = tax + room.rateSummary.tax
          base = base + room.rateSummary.baseFare
        })
        for (let i = 0; i < this.rooms.length; i++) {
          text += ` room number ${i + 1} price is $${this.rooms[i].rateSummary.totalFare} `
        }
        this.alert = true
        this.alertText = text
      } else {
        // this.rooms.forEach(room => {
        price = price + this.rooms[0].rateSummary.totalFare
        tax = tax + this.rooms[0].rateSummary.tax
        base = base + this.rooms[0].rateSummary.baseFare
        // })
      }
      return { price: price.toFixed(2), tax: tax.toFixed(2), base: base.toFixed(2) }
    },
    getNewPrice () {
      let price = 0
      this.rooms.forEach(room => {
        price = price + room.rateSummary.totalFare
      })
      return price.toFixed(2)
    },
    previousNumber (type, i) {
      if (type === 'adult') {
        if (i > 0) {
          let number = 0
          for (let n = 0; n < i; n++) {
            number = number + this.$store.state.hotelSearch.guestsInfo[n].adults
          }
          return number
        } else return 0
      } else {
        if (i > 0) {
          let number = 0
          for (let n = 0; n < i; n++) {
            number = number + this.$store.state.hotelSearch.guestsInfo[n].children
          }
          return number
        } else return 0
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 1) {
          this.currentPage = 1
          this.snackbar = false
          if (this.acceptCancellation && this.acceptTerms) {
            this.el = 2
            window.scrollTo(0, 0)
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please reead and accept on the cancllation policy and terms & conditions to continue'
            return false
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          this.$refs.form.validate()
          if (this.valid) {
            const fullNames = []
            for (let index = 0; index < this.adultFirstNames.length; index++) {
              if (this.adultFirstNames[index] && this.adultLastNames[index]) fullNames.push(`${this.adultFirstNames[index]} ${this.adultLastNames[index]}`)
            }
            for (let index = 0; index < this.childrenFirstNames.length; index++) {
              if (this.childrenFirstNames[index] && this.childrenLastNames[index]) fullNames.push(`${this.childrenFirstNames[index]} ${this.childrenLastNames[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if ((!this.$cookies.isKey('userToken') && this.phone.valid) || this.$cookies.isKey('userToken')) {
                this.snackbar = false
                this.holdBtnLoad = true
                this.prepareGuestsData()
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please provide a valid phone number'
                return false
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for guests'
              return false
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to cotinue'
            return false
          }
        } else if (currentPage === 3) {
          window.scrollTo(0, 0)
          this.currentPage = 3
          this.$refs.form1.validate()
          this.nextLabel = 'Book'
          if (this.valid1) {
            this.snackbar = false
            if ((this.$store.state.writtenAddress && this.paymentType === 'credit') || this.paymentType !== 'credit') {
              if ((this.$store.state.zipCode && this.paymentType === 'credit') || this.paymentType !== 'credit') {
                this.sendPaymentDataToAnet()
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please Enter your ZIP code'
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please Enter your address'
              document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to Book'
            return false
          }
        } else if (currentPage === 4) {
          window.scrollTo(0, 0)
          this.showAlert = false
          this.$router.push({ name: 'Home' })
          return true
        } else {
          this.nextLabel = 'Continue'
          return true
        }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 3) {
        return false
      } else if (currentPage === 2) {
        this.nextLabel = 'Continue'
        return true
      } else return true
    },
    prepareGuestsData () {
      this.guestsData = []
      for (let i = 0; i < this.$store.state.hotelSearch.guestsInfo.length; i++) {
        for (let n = 0; n < this.$store.state.hotelSearch.guestsInfo[i].adults; n++) {
          if (this.isLeader[i] === ((this.previousNumber('adult', i)) + i + n)) {
            const guest = {
              isLead: this.isLeader[i] === ((this.previousNumber('adult', i)) + i + n) ? 1 : 0,
              title: this.guestTitles[(this.previousNumber('adult', i)) + i + n],
              firstName: this.adultFirstNames[(this.previousNumber('adult', i)) + i + n],
              lastName: this.adultLastNames[(this.previousNumber('adult', i)) + i + n],
              guestType: 'Adult',
              InRoom: i + 1,
              smoking: false
            }
            this.guestsData.push(guest)
          } else {
            const guest = {
              isLead: 0,
              title: this.guestTitles[(this.previousNumber('adult', i)) + i + n],
              firstName: this.adultFirstNames[(this.previousNumber('adult', i)) + i + n],
              lastName: this.adultLastNames[(this.previousNumber('adult', i)) + i + n],
              guestType: 'Adult',
              InRoom: i + 1,
              smoking: false
            }
            this.guestsData.push(guest)
          }
        }
      }
      this.holdRooms()
    },
    holdRooms () {
      const body = {
        sessionId: this.searchTerms.sessionId,
        numberOfRooms: this.$store.state.hotelSearch.guestsInfo.length,
        hotelIndex: this.searchTerms.hotelIndex,
        hotelCode: this.searchTerms.hotelIndex,
        hotelName: this.hotel.HotelName,
        lastCancellationDeadLine: this.availability.lastCancellationDeadLine,
        guests: this.guestsData
      }

      if (!this.$cookies.isKey('userToken')) {
        body.phoneNumber = this.phone.nationalNumber
        body.countryIsoCode = this.phone.countryCode
        body.phoneCountryCode = this.phone.countryCallingCode
        body.contact_name_person = this.contact_name_person
        body.email = this.email
      }
      let header
      if (this.$cookies.get('userToken')) header = headers(this.$cookies.get('userToken'))
      else header = headersNoAuth()
      this.$http.post(holdRooms, body, { headers: header }).then(response => {
        if (response.body.data.roomStatus === 'soldOut') {
          this.dataReady = false
          this.soldOut = true
          if (this.$store.state.hotelTimeOutFunction) this.$store.dispatch('clearHotelTimeoutFunction')
          if (this.$store.state.hotelSearchTimeOutFunction) this.$store.dispatch('clearHotelSearchTimeoutFunction')
        } else {
          this.$store.dispatch('setHotelTimeOut', false)
          this.$store.dispatch('setHotelTimeOutFunction', setTimeout(() => {
            this.$store.dispatch('setHotelTimeOut', true)
          }, 20 * 60 * 1000))
          this.holdingRefernce = response.body.data.holdingRefernce
          if (response.body.data.roomStatus === 'priceChanged') {
            this.priceDetails = response.body.data.newPrice
            this.priceChangeDialog = true
          } else {
            window.scrollTo(0, 0)
            this.el = 3
          }
        }
      }).catch((err) => {
        this.showOverlay = false
        this.text = err.body.message
        this.color = 'error'
        this.snackbar = true
      }).finally(() => {
        this.holdBtnLoad = false
      })
    },
    book () {
      this.showOverlay = true
      const body = {
        kountSessionId: sessionStorage.getItem('kountSessionId'),
        sessionId: this.searchTerms.sessionId,
        holdingRefernce: this.holdingRefernce
      }
      if (this.paymentType !== 'credit') {
        delete body.creditCardInfo
        body.redeem = 'redeem'
      } else {
        body.creditCardInfo = {
          cardHolderName: this.cardHolder,
          descriptor: this.authorizeNetToken.dataDescriptor,
          token: this.authorizeNetToken.dataValue,
          postalCode: this.$store.state.zipCode,
          zipCode: this.$store.state.zipCode,
          card_type: 'customer_card',
          address: this.$store.state.writtenAddress,
          city: this.$store.state.writtenCity,
          stateProvinceCode: this.$store.state.writtenState,
          country: this.$store.state.writtenCountry
        }
      }
      let header
      if (this.$cookies.get('userToken')) header = headers(this.$cookies.get('userToken'))
      else header = headersNoAuth()
      this.$http.post(bookHotel, body, { headers: header }).then(response => {
        this.bookingNo = response.body.data.BookingNo
        this.$store.dispatch('setHotelTimeOut', false)
        if (this.$store.state.hotelTimeOutFunction) this.$store.dispatch('clearHotelTimeoutFunction')
        if (this.$store.state.hotelSearchTimeOutFunction) this.$store.dispatch('clearHotelSearchTimeoutFunction')
        this.el = 4
        window.scrollTo(0, 0)
      }).catch(err => {
        this.text = err.body.message
        this.color = 'error'
        this.snackbar = true
        this.disableBookingBtn()
      }).finally(() => {
        this.showOverlay = false
      })
    },
    closeTab () {
      window.close()
    },
    disableBookingBtn () {
      const btns = document.getElementsByClassName('wizard__next pull-right')
      btns.forEach(btn => {
        btn.style.display = 'none'
      })
      setTimeout(() => {
        btns.forEach(btn => {
          btn.style.display = 'block'
        })
      }, 30000)
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.searchTerms = this.$route.params
    if (this.searchTerms.hotel) {
      this.hotel = this.searchTerms.hotel
      this.rooms = this.searchTerms.rooms
      this.priceDetails = this.searchTerms.priceDetails

      for (let i = 0; i < this.$store.state.hotelSearch.guestsInfo.length; i++) {
        for (let n = 0; n < this.$store.state.hotelSearch.guestsInfo[i].adults; n++) {
          this.isLeader[i] = 0 + (i) + (this.previousNumber('adult', i))
        }
      }
      const term = `sessionId=${this.searchTerms.sessionId}&roomIndex=${this.searchTerms.roomIndex}&ratePlanCode=${this.searchTerms.ratePlanCode}&bedGroupId=${this.searchTerms.bedGroupId}`
      this.$http.get(hotelAvailability(term), { headers: headersNoAuth() }).then(res => {
        if (res.body.data.roomStatus === 'soldOut') {
          this.loaded = false
          this.dataReady = false
          this.soldOut = true
        } else {
          this.loaded = false
          this.dataReady = true
          this.soldOut = false
          this.availability = res.body.data
          if (this.availability.priceChanged) this.priceDetails = this.availability.newPrice
          this.$store.dispatch('setHotelTimeOut', false)
          document.body.style.height = '100%'
          this.$store.dispatch('setHotelTimeOutFunction', setTimeout(() => {
            this.$store.dispatch('setHotelTimeOut', true)
          }, 20 * 60 * 1000)
          )
        }
      }, () => {
        this.loaded = false
        this.dataReady = false
        this.soldOut = false
      })
    } else {
      this.loaded = false
      this.dataReady = false
      this.soldOut = false
    }
  },
  beforeDestroy () {
    clearTimeout(this.hotelsTimeOut)
    this.hotelsOverlay = false
  }
}
</script>

<style>
   #returnBtn:hover {
    cursor: pointer;
   }
  .ats .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .fare .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__body__actions[data-v-c21d83ca] {
    background-color:white !important;
    border-top: 0.1px solid #e3e3e3 !important;
  }
  .wizard__body[data-v-c21d83ca] {
    margin-top: 30px !important;
    min-height: auto !important;
    margin-left: 20px !important;
    margin-right: 10px !important;
    border: none !important;
    box-shadow: 1px 3px 3px gray !important;
  }
  .ats .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .ats .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .fare .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .fare .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__arrow[data-v-c21d83ca] {
    border: none !important;
  }
  .remove-controls input[type='number'] {
    -moz-appearance: textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
</style>
